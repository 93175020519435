.welcome-bottom-box{
    min-width: 880px;
    box-shadow: 0 3px 8px 0 #0000001a;
    .ant-pro-card-body{
        // padding: 10px;
    }   
}
.welcome-top-box{
    min-width: 880px;
    box-shadow: 0 3px 8px 0 #0000001a;
    .top-box-row01{
        .inner-card{
            &:nth-child(1){
                .ant-pro-card-body{
                    .ant-pro-card{
                        background-image: linear-gradient(to right, #92adf943, #92adf925);
                    }
                }
            }
            &:nth-child(2){
                .ant-pro-card-body{
                    .ant-pro-card{
                        background-image: linear-gradient(to right, #8dd3c88d, #8dd3c811);
                    }
                }
            }
            &:nth-child(3){
                .ant-pro-card-body{
                    .ant-pro-card{
                        background-image: linear-gradient(to right, #e7ae7963, #e7ae7910);
                    }
                }
            }
        }
    }
    .top-box-row02{
        .inner-card{
            &:nth-child(1){
                .ant-pro-card-body{
                    .ant-pro-card{
                        background-image: linear-gradient(to right, #b193e654, #b193e60c);
                    }
                }
            }
            &:nth-child(2){
                .ant-pro-card-body{
                    .ant-pro-card{
                        background-image: linear-gradient(to right, #f8e07276, #f8e07207);
                    }
                }
            }
            &:nth-child(3){
                .ant-pro-card-body{
                    .ant-pro-card{
                        background-image: linear-gradient(to right, #de88ef5a, #de88ef0c);
                    }
                }
            }
        }
    }
    .inner-card{
        padding: 6px;
        
        >.ant-pro-card{
            // padding: 10px;
            // border: 1px solid #ddd;
            border-radius: 8px;
            box-shadow: 0 3px 8px 0 #0000001a;
            transition-property: box-shadow;
            transition-duration: 0.3s;
            &:hover{
                box-shadow: 0 5px 12px 0 #100f0f50;
            }
            >.ant-pro-card-body{
                .ant-pro-card{
                    // background: #ccf3d91a;
                }
            }
            .img-box{
                width: 80px;
                position: absolute;
                right: 12px;
                bottom: 16px;
                >img{
                    width: 100%;
                }
            }
            .number-card{
                padding: 16px 22px 16px;
            }
        }
        .ant-pro-statistic-card-footer{
            margin: 0px;
            padding: 0px;
            padding: 3px 0px;
        }
        // &.border-right-bottom{
        //     border-right: 1px solid #ddd;
        //     border-bottom: 1px solid #ddd;
        // }
        // &.border-bottom{
        //     border-bottom: 1px solid #ddd;
        // }
        // &.border-right{
        //     border-right: 1px solid #ddd;
        // }
    }
    .ant-pro-card-body{
        padding: 0px;
    }
}
.welcome-card-box{
    .ant-card-body{
        padding-top: 0px;
    }
    .card-box-list-box{
        li{
            height: 60px !important;
        }
        &.card-box-person-box{
            .ant-list-item-meta-avatar{
                padding-top: 6px;
            }
        }
        &.card-box-team-box{
            .ant-list-item-meta-title{
                line-height: 32px !important;
                height: 32px !important;
            }
        }
    }
}
.welcome-select-box{
    .ant-select{
        border-bottom: 1px soild #f0f0f0;
        .ant-select-selector{
            border: none !important;
            border-color: #f0f0f0;
            border-bottom: 1px solid #ddd;
            border-top-left-radius: 8px !important;
            border-top-right-radius: 8px !important;
        }
    }
   
}