
@import 'variables';
.userinfo-box{
    .ant-descriptions{
        margin: 0px !important;
    }
    >div{
        .ant-descriptions-title{
            font-size: 20px;
            // border-bottom: 1px solid #ddd;
            width: 100%;
            padding-bottom: 5px;
            border-bottom: 3px solid $title-bottom-color;
        }
        >div{
            width: 100%;
            padding: 20px;
            box-shadow: 0 3px 8px 0 #0000001a;
            transition-property: box-shadow;
            transition-duration: 0.3s;
            &:hover{
                box-shadow: 0 5px 12px 0 #100f0f50;
            }
        }
        .ant-descriptions{
            margin: 0px !important;
            .ant-descriptions-item-label{
                width: 100px;
                text-align: right;
                display: inline-block;
                height: 24px;
                line-height: 24px;
                font-size: 16px;
                font-weight: 500;
                color: #333;
            }
            .ant-descriptions-item{
                padding-bottom: 8px;
                
            }
            .ant-descriptions-item-content{
                padding-left: 16px;
                height: 24px;
                line-height: 24px;
                font-size: 16px;
                font-weight: 500;
                color: #666;
                >button{
                    height: 24px;
                    line-height: 24px;
                    padding: 0px;
                    span{
                        height: 24px;
                        line-height: 24px;
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
    
}