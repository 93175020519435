html,
body {
  height: 100%;
  overflow: hidden;
}

#root {
  height: 50vh;
}

.top-tabnav {
  flex: 0;
  border-bottom: solid 1px var(--adm-color-border);
}

.body-tabnav {
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

.bottom-tabnav {
  flex: 0;
  border-top: solid 1px var(--adm-color-border);
}

.adm-jumbo-tabs-content {
  padding: 0;
}

.adm-jumbo-tabs-content svg {
  height: 12px;
}

.mobile-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  margin-top: -75px;
  margin-left: -75px;
  border-radius: 16px;
}
