@import 'variables';
.shadow-box-normal{
    box-shadow: 0 3px 8px 0 #0000001a;
    &:hover{
        box-shadow: 0 5px 12px 0 #100f0f50;
    }
}
.shadow-box-animate{
    box-shadow: 0 3px 8px 0 #0000001a;
    &:hover{
        box-shadow: 0 5px 12px 0 #100f0f50;
    }
}
.normal-table{
    box-shadow: 0 3px 8px 0 #0000001a;
    .ant-table-container{
        border: 1px solid $table_header_border_td !important;
        border-radius: 0px !important;
    }
    .ant-table-tbody td{
        border-top: 1px solid $table_header_border_td !important;
        &:not(:last-child){
            border-right: 1px solid $table_header_border_td !important;
        }
    }
    .ant-table-tbody tr{
        &:nth-child(odd){
            background: $table_even_bg; /* 偶数行背景色 */
        }
        &:nth-child(even){
            background: $table_odd_bg; /* 奇数行背景色 */
        }
    }
    .ant-table-thead tr .ant-table-cell{
        border-bottom: 2px solid $table_header_border_th !important;
    }
    .ant-table-thead th, .ant-table-thead td{
        // background: #12376511 !important; /* 设置背景颜色 */
        border-radius: 0px !important;
        background: $table_header_bg !important;
        &:not(:last-child){
            border-right: 1px solid $table_header_border_td !important;
        }
        &:hover{
            $table_header_bg_hover: #d6e0fa;
        }
    }
    .tbody{
        >tr:odd{

        }
    }
    .even-row {
        background-color: $table_even_bg; /* 偶数行背景色 */
    }
    .odd-row {
        background-color: $table_odd_bg; /* 奇数行背景色 */
    }
    .ant-table-bordered .ant-table-cell {
        border-color: #e5ecfc !important; /* 线段颜色 */
    }
}
// 公告
.messagelist-form{

}
.list-rate-box{
    position: relative;
    top: -3px;
}
// 标签外层容器允许换行
.space-flex-box{
    flex-wrap: wrap;
}
.avatar-box{
    flex-wrap: wrap;
}
.list-table-tips{
    margin-right: 3px;
    background: #5890f0f3;
    border-radius: 4px;
    line-height: 28px;
    height: 28px;
    padding: 2px 4px;
    color: #fff;
    // 标签容器内文本不允许换行
    white-space: nowrap;
}
.message-list-modal-title{
    height: 36px;
    line-height: 36px;
    // border-bottom: 3px solid $title-bottom-color;
}
.pro-table{
    // checkbox居中
    .ant-table-selection-column {
        text-align: center;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .ant-pro-card-body{
        padding: 0px !important;
    } 
    .ant-table-container{
        border: 1px solid $table_header_border_td !important;
        border-radius: 0px !important;
        // >table{
        //     border-radius: 0px !important;
        // }
        .ant-table-tbody tr{
            &:nth-child(odd){
                background: $table_even_bg; /* 偶数行背景色 */
            }
            &:nth-child(even){
                background: $table_odd_bg; /* 奇数行背景色 */
            }
        }
    }
    .ant-table-header{
        border-radius: 0px !important;
    }
    .ant-table-thead th, .ant-table-thead td{
        // background: #12376511 !important; /* 设置背景颜色 */
        border-radius: 0px !important;
        background: $table_header_bg !important;
        border-bottom: 2px solid $table_header_border_th !important;
        &:not(:last-child){
            border-right: 1px solid $table_header_border_td !important;
        }
        &:hover{
            $table_header_bg_hover: #d6e0fa;
        }
    }
    .ant-table-tbody td{
        border-top: 1px solid $table_header_border_td !important;
        &:not(:last-child){
            border-right: 1px solid $table_header_border_td !important;
        }
    }
    .even-row {
        background-color: $table_even_bg; /* 偶数行背景色 */
    }
    .odd-row {
        background-color: $table_odd_bg; /* 奇数行背景色 */
    }
    >div{
        box-shadow: 0 3px 8px 0 #0000001a;
    }
}
.drawer-box{
    .ant-space{
        gap: 0px !important;
    }
    li.ant-timeline-item{
        padding-bottom: 24px;
        .ant-timeline-item-content{
            position: relative;
            top: -12px;
        }
    }
}

.custom-tooltips{
    /* 修改 Ant Design Tooltip 内文本颜色 */
    .ant-tooltip-inner {
        color: #000000;
    }
}

.login-page{
    background-color: transparent !important;
    .bottom_box{
        a{
            color: #ddd;
            text-decoration: none;
        }
        position: fixed;
        width: 100vw;
        height: 30px;
        text-align: center;
        bottom: 1px;
    }
    .login_bg{
        height: 100%;
        width: 100%;
        z-index: -1;
        display: inline-block;
        position: fixed;
        overflow: hidden;
        top: 0px;
        left: 0px;
        background-image: url(../../public/loginbg02.jpg);
        background-color: #213873;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        // &:after{
        //     display: inline-block;
        //     content: '';
        //     width: 200vw;
        //     height: 200vh;
        //     background-color: #213873;
        //     position: absolute;
        //     right: 50vw;
        //     top: -50vh;
        //     transform: rotate(-45deg);
        //     transform-origin: 100% 36%;
        //     z-index: 1;
            
        // }
        // &:before{
        //     background-color: #1c3066;
        //     content: '';
        //     display: inline-block;
        //     width: 200vw;
        //     height: 200vh;
        //     position: absolute;
        //     left: 49vw;
        //     top: -50vh;
        //     transform: rotate(-45deg);
        //     transform-origin: 0% 36%;
        //     z-index: 1;
        // }
    }
}