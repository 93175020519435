.layout-box{
    background: linear-gradient(to bottom, #052241, #0c4d94);
    .layout-menu-box{
        // background: transparent;
    }
    .sider-box{
        background: transparent;
    }
}
// .banner-header{
//     border:;
// }

ul.menu-box{
    overflow-y: scroll;
    &::-webkit-scrollbar {
        background-color: #041527; /* 设置滚动条的背景颜色 */
    }
    &::-webkit-scrollbar {
        width: 6px; /* 设置滚动条的宽度 */
        border-radius: 6px; /* 设置按钮的圆角半径 */
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(255,255,255, .22); /* 设置滚动条的颜色 */
        border-radius: 8px; /* 设置按钮的圆角半径 */
    }
    li{
        border-radius: 0px !important;
        margin: 0px;
    }
    // menu title
    .ant-menu-submenu-title{
        border-radius: 0px !important;
        &:hover{
            background: #043262dd !important;
        }
    }
    // menu li items
    li.ant-menu-item{
        &:hover{
            background: #043262dd !important;
        }
    }
    // menu li items selected
    li.ant-menu-item-selected{
        background: #0164cedd;
        //  #0766cc;
    }
    li .ant-menu-sub{
        background: #032346dd !important;
    }
    &.ant-menu{
        background: transparent;
    }
}
.marquee-box{
    width: 100%;
    height: 64px;
    position: relative;
    padding: 16px;
    >div{
        width: 100%;
        height: 36px;
        border-radius: 2px;
        background: #f0f2f587;
        overflow: hidden;
        line-height: 36px;
        position: relative;
    }
}
.marqueeT{
    animation: marqueeT 20s linear infinite;
    white-space: nowrap;
    position: absolute;
    top: 0px;
}
@keyframes marqueeT {
    0% {left: 0%;}
    20% {left: 0%;}
    100%{ left: -105%; }
}
