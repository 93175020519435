$primary_color: #3875f6; // 蓝色
// $primary_success_color: #6fbe91;
// $primary_success_color_hover: #42cb7e;
// $primary_success_color_active: #71f4aa;

$primary_success_color: #2b71ec;;
$primary_success_color_hover: #1262ec;
$primary_success_color_active: #245fc5;

$border-color-light: #ddd;

$title-bottom-color: #1c79ddbe;

$table_header_bg: #e2e9fb;
$table_header_bg_hover: #d6e0fa;
$table_header_border_td: #cfdbf9;
$table_header_border_th: #c4cff4;

$table_odd_bg: #f6f8fd;
$table_even_bg: #ffffff;