@import 'variables';
.tab-box{
    font-size: 14px;
    display: flex;
    height: 49px;
    overflow-x: auto;
    padding-top: 17px;
    overflow: hidden;
}
.nav-items{
    cursor: pointer;
    flex-direction: 'row';
    flex-shrink: 0;
    display: flex;
    white-space: nowrap;
    padding: 0px 20px 0px 16px;
    height: 32px;
    line-height: 32px;
    margin: 0 2px;
    // border: '1px solid',
    // borderColor: colorBgContainer,x
    border-bottom: none;
    border: 1px solid $border-color-light;
    border-bottom: 1px solid transparent;
    position: relative;
    .close-btn{
        position: absolute;
        right: 3px;
        top: 0px;

        >span{
            position: relative;
            top: -2px;
            padding: 4px;
            font-size: 9px;
            font-weight: 700;
            padding: 3px;
            width: 14px;
            height: 14px;
            &:hover{
                color: #808080;
                border-radius: 50%;
                background: #c3c3c3;
            }
            // &:hover{
            //     border-radius: 50%;
            //     background: #646464;
            //     padding: 2px;
            // }
        }
        // i{
        //     padding: 4px;
        //     font-size: 9px;
        //     font-weight: 700;
        //     &:hover{
        //         color: #fff;
        //     }
        // }
    }
    span{
        height: 32px;
        color: #333; 
    }
    &.nav-items-active{
        background: $primary_success_color;
        border: 1px solid $primary_success_color;
        span{
            color: #fff;
        }
        &:hover{
            background: $primary_success_color_hover;
            border: 1px solid $primary_success_color_hover;
        }
        &:active{
            background: $primary_success_color_active;
            border: 1px solid $primary_success_color_active;
        }
    }
    &:hover{
        background: rgb(236, 236, 236);
    }
    &:active{
        background: rgb(228, 228, 228);
    }
}